<template>
  <v-img
    dark
    :src="backgroundImage"
    :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
    :max-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
  >
    <v-container fluid class="fill-height">
      <v-row align="center">
        <v-col cols="md-4 sm-12">
          <v-avatar size="300">
            <img v-bind:src="me" alt="Andrew Perry" />
          </v-avatar>
          <h1 class="display-1 font-weight-thin mb-4">Andrew Perry</h1>
          <h4 class="subheading">DevOps Extraordinaire</h4>
        </v-col>
        <v-col cols="2 sm-12 md-8" justify="center"> </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
import me from "../public/img/me.jpg";
import small_img from "../public/img/clark-young-Qtbx4j4UjRg-unsplash-small.jpg";
import medium_img from "../public/img/clark-young-Qtbx4j4UjRg-unsplash-medium.jpg";
export default {
  computed: {
    backgroundImage: function() {
      let image;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          image = this.backgrounds.small;
        case "sm":
          image = this.backgrounds.small;
        case "md":
          image = this.backgrounds.small;
        case "lg":
          image = this.backgrounds.medium;
        case "xl":
          image = this.backgrounds.medium;
      }
      return image;
    }
  },
  data: function() {
    return {
      me: me,
      backgrounds: {
        small: small_img,
        medium: medium_img
      }
    };
  }
};
</script>
